import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MyProfileService } from 'src/app/modules/dashboard/dash-info-cards/service/my-profile.service';
import { AuthUserService } from 'src/app/services/auth-user.service';

@Injectable({
  providedIn: 'root',
})
export class EmpFeedbackGuard implements CanActivate {
  ecStatus: boolean = false;
  constructor(
    private profileService: MyProfileService,
    private authService: AuthUserService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return forkJoin([
      this.authService.getActiveCouncil(),
      this.profileService.getDetails(),
    ]).pipe(
      map(([councilData, profileData]) => {
        const ecStatus = councilData?.[0]?.value || false;
        const isAuthorized =
          profileData?.isDcManager ||
          profileData?.isRm ||
          profileData?.isAdminManager ||
          (ecStatus && profileData?.isEcManager);

        return isAuthorized || this.router.createUrlTree(['/main-dashboard']);
      }),
      catchError(() => of(this.router.createUrlTree(['/main-dashboard'])))
    );
  }
}
