import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MyProfileService } from 'src/app/modules/dashboard/dash-info-cards/service/my-profile.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  isAdmin: boolean;
  isManager: boolean;
  isHr: boolean;
  transactionAccess: boolean;
  isReportingManager: boolean;

  constructor(
    private router: Router,
    private authUser: AuthUserService,
    private profileService: MyProfileService
  ) {
    this.isAdmin = false;
    this.isManager = false;
    this.isHr = false;
    this.transactionAccess = false;
    this.isReportingManager = false;
  }
  canActivateChild(): boolean {
    this.profileService.getDetails().subscribe((item) => {
      if (item) {
        this.transactionAccess = item.getAccessToTransactionAnalytics;
        this.isManager = item.isEcManager||item.isDcManager;
        this.isHr = item.isHR;
        this.isReportingManager = item.isRm;
      }
      if (item?.isAdminManager || item?.admin) {
        this.isAdmin = true;
      }
    });
    if (
      this.transactionAccess ||
      this.isManager ||
      this.isHr ||
      this.isAdmin ||
      this.isReportingManager
    ) {
      return true;
    } else {
      return false;
    }
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let url: string = state.url;
    if (this.authUser.CheckuserState() == true) {
      this.authUser.startRefreshTokenTimer();
      return true;
    }
    this.authUser.stopRefreshTokenTimer();
    let count = localStorage.getItem('notificationCount');
    let neoToken = localStorage.getItem('neo-token');
    let selectedEmail = localStorage.getItem('selectedEmail');
    localStorage.clear();
    localStorage.setItem('redirecturl', url);
    if (count) localStorage.setItem('notificationCount', count);
    else {
      localStorage.setItem('notificationCount', '0');
    }
    if (neoToken != null) {
      localStorage.setItem('neo-token', neoToken);
    }
    if (selectedEmail != null) {
      localStorage.setItem('selectedEmail', selectedEmail);
    }
    this.router.navigate(['/login']);
    return false;
  }
}
