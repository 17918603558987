import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CommonService } from 'src/app/services/common.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { MyProfileService } from '../../dashboard/dash-info-cards/service/my-profile.service';
@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css'],
})
export class AdminSidebarComponent implements OnInit, AfterViewChecked {
  mobile: boolean;
  isManager: boolean = false;
  isAdmin: boolean = false;
  isHr: boolean = false;
  transactionAccess: boolean = false;
  isReportingManager: boolean = false;
  constructor(
    private commonService: CommonService,
    private _responsiveService: ResponsiveService,
    private profileService: MyProfileService
  ) {
    this.mobile = false;
  }

  ngOnInit(): void {
    this.checkMobile();
    this._responsiveService.checkWidth();
    this.profileService.getDetails().subscribe((item) => {
      if (item) {
        this.isHr = item.isHR;
        this.isReportingManager = item.isRm;
        this.transactionAccess = item.getAccessToTransactionAnalytics;
        if (item?.admin || item?.isAdminManager) {
          this.isAdmin = true;
        }
        this.isManager = item?.isEcManager || item?.isDcManager || item?.isRm;
      }
    });
  }

  ngAfterViewChecked(): void {
    this.checkMobile();
  }

  checkMobile(): void {
    this._responsiveService.getMobileStatus().subscribe((isMobile) => {
      if (isMobile) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  }

  close(): void {
    if (this.mobile) {
      this.commonService.setAdminSideNavOpened(false);
    }
  }
}
