import { CommonService } from "src/app/services/common.service";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { ResponsiveService } from "../app/services/responsive.service";
import { AuthUserService } from "./services/auth-user.service";
import { BnNgIdleService } from 'bn-ng-idle';
import { FullServerDownDetails, PartialServerDownDetails } from "./shared/models/server-down.model";
import { MatDrawerMode } from "@angular/material/sidenav";
import { ChangestatusService } from "./services/changestatus.service";
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { MatDialog } from "@angular/material/dialog";
import { SuccessModalComponent } from './shared/components/success-modal/success-modal.component';
import { ToastrService } from 'ngx-toastr';
import { EncryptionService } from './services/encryption.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewChecked {
  title: String;
  mobile: boolean;
  isLoggedIn: boolean;
  isLoading: boolean;
  href: String;
  oneTime: boolean;
  loginServerStatus: PartialServerDownDetails;
  fullServerStatus: FullServerDownDetails;
  maintainencePageStatus: PartialServerDownDetails
  sideNavMode: MatDrawerMode;
  opened: boolean;
  isAdminPortalOpened: boolean;
  screenSize: string;
  show = false;
  constructor(
    private _responsiveService: ResponsiveService,
    private authUser: AuthUserService,
    private _authUserService: AuthUserService,
    private router: Router,
    private cd: ChangeDetectorRef,
    public commonService: CommonService,
    private bnIdle: BnNgIdleService,
    private authService: MsalService,
    private changeStatusService: ChangestatusService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private encryptionService:EncryptionService
  ) {
    this.changeStatusService.getldr().subscribe(() => {
      this.show = this.commonService.getleaderboardStatus();
    })
    this.mobile = false;
    this.loginServerStatus = this.commonService.getLoginServerStatus();
    this.fullServerStatus = this.commonService.getFullServerStatus();
    this.maintainencePageStatus = this.commonService.getMaintainencePageStatus();
    this.opened = this.commonService.getAdminSideNavOpened();
    this.isAdminPortalOpened = commonService.getAdminPortalOpened();
    this.sideNavMode = "side";
    this.href = "";
    this.oneTime = true;
    this.isLoading = true;
    this.isLoggedIn = false;
    this.title = "contripoint";
    this.screenSize = "";
  }
  ngOnInit(): void {
    this.checkMobile();
    this.checkInactivity();
    this.opened = this.commonService.getAdminSideNavOpened();
    this.onResize();
    this.checkScreenStatus();
    this.getToken()
  }
  getcouncilData() {
    this._authUserService.getActiveCouncil().subscribe(
      (data) => {
        if (data) {
          const encryptedData = this.encryptionService.encryptData(data);
          localStorage.setItem('councilData', encryptedData);
        }
      },
      (error) => {
        this.toastr.error(error.error);
      }
    );
  }
  
  getToken() {
    this.authService.handleRedirectObservable().subscribe({
      next: (response: AuthenticationResult) => {
        this.goToDashboard(response.account?.username, response.accessToken);
      },
      error: (error) => {
        this.toastr.error('Unable to login contripoint');
      }
    });
  }
  goToDashboard(email?: string, token?: string): void {
    this._authUserService.setToken(email, token).then(() => {
      this.getcouncilData()
      if (localStorage.getItem('redirecturl')) {
        let url = localStorage.getItem('redirecturl');
        if (url?.includes("dashboard?redirect=delegateContributions")) {
          this.router.navigate(["/delegate-contributions/delegate-dashboard"]);
        }

        else if (url?.includes('/dashboard?redirect=reviewActivity')) {
          this.router.navigate(["/review-activities/review-table"]);
        }
        else if (url?.includes('my-contribution-history')) {
          this.router.navigate(["main-dashboard"]);
        }
        else if (url?.includes('team-contribution-history')) {
          this.router.navigate(["main-dashboard"]);
        }
        else {
          this.router.navigate([url]);
        }
     
      }

      else {
        this.router.navigate(["main-dashboard"]);
      }
      // sessionStorage.clear();
    }).then(() => {
      this._authUserService.getEmployeeDetailBehaviorSubject().subscribe((item) => {
        if (item) {
          if (item.notifications && this._authUserService.getNotificationModalBoolean() && item.isFirstTimeUser == false) {
            this.openDialog();
            this._authUserService.setNotificationModalBoolean(false);
          }
          else if (item.isFirstTimeUser && this._authUserService.getWelcomeModalBoolean()) {
            if (localStorage.getItem("firstTimeUser") === undefined || localStorage.getItem("firstTimeUser") === null) {
              localStorage.setItem("firstTimeUser", "true");
              this.commonService.setFirstTimeUser(true);
            }
          }
        }
      });
    });
  }
  openDialog(): void {
    const DialogCommentsRef = this.dialog.open(SuccessModalComponent, {
      width: "500px",
    });
  }
  checkMobile(): void {
    this._responsiveService.getMobileStatus().subscribe((isMobile) => {
      if (isMobile) {
        this.sideNavMode = "over";
        this.commonService.setAdminSideNavOpened(false);
        this.mobile = true;
      } else {
        this.sideNavMode = "side";
        this.commonService.setAdminSideNavOpened(true);
        this.mobile = false;
      }
    });
  }
  checkScreenStatus(): void {
    this._responsiveService.getScreenStatus().subscribe((screenSize: string) => {
      if (screenSize) {
        this.screenSize = screenSize;
      }
    });
  }
  isScreenSizeXSM(): boolean {
    return this.screenSize === "xsm";
  }
  isScreenSizeXS(): boolean {
    return this.screenSize === "xs";
  }
  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    if (localStorage.getItem('leaderboardStatus') === 'close') {
      this.commonService.setLeaderboardStatus(false);
      this.changeStatusService.setldr('close');
    }
    localStorage.setItem('leaderboardStatus', 'close')
    if (localStorage.getItem('nominationStatus') === 'close') {
      this.commonService.setNominationSideBarStatus(false);
    }
    localStorage.setItem('nominationStatus', 'close')
  }

  sidenavFunc(): boolean {
    return this.commonService.getleaderboardStatus();
  }
  viewJourneyFunc() {

    return this.commonService.getNominationSideBarStatus();

  }
  sidebarFunc(): void {
    localStorage.setItem('leaderboardStatus', 'open')
    this.commonService.sendUpdate('side leadeboard');
    this.commonService.setLeaderboardStatus(true);
    this.changeStatusService.setldr("hide");
  }
  isAdminOpened(): boolean {
    return this.commonService.getAdminPortalOpened();
  }
  ngAfterViewChecked(): void {
    this.checkMobile();
    this.sidenavFunc();
    this.opened = this.commonService.getAdminSideNavOpened();
    this.tutorialGuideSteps();
    this.authUser.getEmployeeDetailBehaviorSubject().subscribe((res) => {
      if (res) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
    this.cd.detectChanges();
    this.href = this.router.url;
    // if (this.oneTime && (this.href == "/dashboard?redirect=reviewActivity"|| this.href == "/dashboard?redirect=delegateContributions")) {
    //   window.scrollTo(0, document.body.scrollHeight);
    //   history.scrollRestoration = "manual";
    //   this.oneTime=false;
    // }
    this.commonService.setJoyRideStepName("");
  }

  tutorialGuideSteps(): void {
    let joyrideStepName = this.commonService.getJoyRideStepName();
    if (
      joyrideStepName === "three-tabs" ||
      joyrideStepName === "review-activity"
    ) {

      if (this.mobile) {
        window.scrollTo(0, 2200);
      }
      else if (!this.commonService.getIsEventDataFound()) {
        window.scrollTo(0, 1000);
      } else {
        window.scrollTo(0, 1200);
      }
      history.scrollRestoration = "manual";
    } else if (joyrideStepName === "contribution-parameters") {
      if (this.mobile) {
        window.scrollTo(0, 900);
      }
      else if (!this.commonService.getIsEventDataFound()) {
        window.scrollTo(0, 400);
      } else {
        window.scrollTo(0, 600);
      }
      history.scrollRestoration = "manual";
    } else if (
      joyrideStepName === "navbar" ||
      joyrideStepName === "contribution-history"
    ) {
      window.scrollTo(0, 0);
      history.scrollRestoration = "manual";
    } else if (
      joyrideStepName === "profile" ||
      joyrideStepName === "leaderboard"
    ) {
      if (this.mobile && joyrideStepName === "profile") {
        window.scrollTo(0, 100);
      }
      else if (this.mobile && joyrideStepName === "leaderboard") {
        window.scrollTo(0, 400);
      }
      else if (!this.commonService.getIsEventDataFound()) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 200);
      }
      history.scrollRestoration = "manual";
    }
  }

  checkInactivity(): void {
    this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        let count = localStorage.getItem('notificationCount');
        let neoToken = localStorage.getItem('neo-token');
        localStorage.clear();
        if (count)
          localStorage.setItem('notificationCount', count);
        else {
          localStorage.setItem('notificationCount', '0');
        }
        if (neoToken != null) {
          localStorage.setItem('neo-token', neoToken);
        }
        window.location.reload()
      }
    });
  }

  onResize(): void {
    this._responsiveService.checkWidth();
  }

  open(): void {
    this.commonService.setAdminSideNavOpened(true);
  }

  close(): void {
    if (this.mobile) {
      this.commonService.setAdminSideNavOpened(false);
    }
  }
}