import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  BulkNominationReqBody,
  NominationActionReqBody,
} from 'src/app/shared/models/generic.nomination-action.model';
import {
  Reminder,
  RevertNomination,
  dataList,
  scheduler_data,
} from 'src/app/shared/models/hr-nomination.model';
import { NominateCount } from 'src/app/shared/models/hr-nomination.model';
import {
  AwardWinner,
  CheckScheduledNomination,
  Mail,
  NominationAwardType,
  ScheduledNominationData,
  ToggleVisibility,
  ViewWinnerData,
  setAwardResponse,
} from 'src/app/shared/models/nomination-award-type.model';
import { NominationReview } from 'src/app/shared/models/hr-nomination.model';
import { quarter } from 'src/app/shared/models/hr-nomination.model';
import { attachmentDetails } from 'src/app/shared/models/general.model';
import { Analytics } from 'src/app/shared/models/nomination-review.model';
import { ViewJournyNominations } from '../components/nominate-card/nominate-card.component';
import { WinnerListData } from 'src/app/shared/models/nomination-award-type.model';
import { GetAwardListDetails } from '../components/assign-winners/assign-winners.component';

@Injectable({
  providedIn: 'root',
})
export class NominationService {
  constructor(private http: HttpClient) {}
  public getQuarter: BehaviorSubject<quarter> = new BehaviorSubject<quarter>(
    {} as quarter
  );
  public openModal: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public viewJourney: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public updateModals: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  // public addNewAward:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
  public reviewNomination(
    startDate: string,
    endDate: string,
    pageNo: number
  ): Observable<Array<NominationReview>> {
    let requestBody = {
      fromDate: `${startDate} 00:00:00.001`,
      toDate: `${endDate} 23:59:59.999`,
      pageNo: pageNo,
    };
    return this.http.post<Array<NominationReview>>(
      'contripoint/review/nominations',
      requestBody
    );
  }

  public getNominationCount(
    startDate: string,
    endDate: string
  ): Observable<NominateCount> {
    let requestBody = {
      fromDate: `${startDate} 00:00:00.001`,
      toDate: `${endDate} 23:59:59.999`,
    };
    return this.http.post<NominateCount>(
      'contripoint/nomination-count',
      requestBody
    );
  }

  public approveAward(reqBody: NominationActionReqBody): Observable<string> {
    let stringifyReqBody = JSON.stringify(reqBody);
    const formData = new FormData();
    formData.append('formDetails', stringifyReqBody);
    return this.http.post<string>('contripoint/approve/award', formData);
  }

  public rejectAward(reqBody: NominationActionReqBody): Observable<string> {
    let stringifyReqBody = JSON.stringify(reqBody);
    const formData = new FormData();
    formData.append('formDetails', stringifyReqBody);
    return this.http.post<string>('contripoint/dismiss/award', formData);
  }

  public bulkApproveAward(reqBody: BulkNominationReqBody): Observable<string> {
    return this.http.post<string>('contripoint/bulk/approve/award', reqBody);
  }

  public getAwards(): Observable<Array<NominationAwardType>> {
    return this.http.get<Array<NominationAwardType>>('contripoint/get/awards');
  }
  public setAwards(): Observable<setAwardResponse> {
    return this.http.get<setAwardResponse>('contripoint/set/award');
  }


  public getNomineeList() {
    return this.http.get<dataList>('contripoint/fetch/employee/dropdown/list');
  }

  public getRecommendedByList(): Observable<dataList> {
    return this.http.get<dataList>('contripoint/fetch/management/dropdown');
  }

  public createNomination(obj: FormData): Observable<number> {
    return this.http.post<number>('contripoint/add/new/nomination', obj);
  }

  public draftNomination(obj: FormData) {
    return this.http.post<FormData>('contripoint/hr/drafts', obj);
  }

  public editNomination(obj: Number) {
    return this.http.post<any>('contripoint/edit/hr-nomination', obj);
  }

  public getNominationDetail(
    recommendedByEmail: String,
    minDate: string,
    maxDate: string,
    ecDcList: Array<ViewJournyNominations>
  ) {
    let obj = {
      email: recommendedByEmail,
      fromDate: `${minDate} 00:00:00.001`,
      toDate: `${maxDate} 23:59:59.999`,
      ecDcList: ecDcList,
    };
    return this.http.post<any>('contripoint/view/journey', obj);
  }

  public deleteNomination(id: number) {
    return this.http.delete<number>('contripoint/recommendation/delete/' + id);
  }

  public getEcTechList(): Observable<Array<string>> {
    return this.http.get<Array<string>>('contripoint/fetch/ec-dropdown');
  }

  public getDcTechList(): Observable<Array<string>> {
    return this.http.get<Array<string>>('contripoint/fetch/dc-dropdown');
  }

  public revertByHr(reqBody: RevertNomination): Observable<string> {
    return this.http.post<string>('contripoint/revert/back/by/hr', reqBody);
  }

  public downloadAttachment(id: number): Observable<attachmentDetails> {
    let reqParam = new HttpParams().set('id', id);
    return this.http.get<attachmentDetails>(
      'contripoint/recommendation/download/',
      {
        params: reqParam,
      }
    );
  }

  public awardVisibilty(
    id: number,
    flag: boolean
  ): Observable<ToggleVisibility> {
    let data = { awardId: id, flag: flag };
    return this.http.post<ToggleVisibility>(
      'contripoint/awards/visibility',
      data
    );
  }

  public addAward(reqBody: object): Observable<NominationAwardType> {
    return this.http.post<NominationAwardType>(
      'contripoint/add/new-award',
      reqBody
    );
  }

  public updateAward(reqBody: object): Observable<NominationAwardType> {
    return this.http.post<NominationAwardType>(
      'contripoint/update/award',
      reqBody
    );
  }

  public awardDropdown(): Observable<WinnerListData> {
    return this.http.get<WinnerListData>('contripoint/award/dropdown');
  }

  public winnerList(award: Array<string>): Observable<ViewWinnerData> {
    let reqBody = {
      awardList: award,
    };
    return this.http.post<ViewWinnerData>('contripoint/view/award', reqBody);
  }
  public finalwinnerList(
    award: Array<string>,
    quarterStart: string,
    quarterEnd: string
  ): Observable<setAwardResponse> {
    let reqBody = {
      awardList: award,
      quarterStart: quarterStart,
      quarterEnd: quarterEnd,
    };
    return this.http.post<setAwardResponse>('contripoint/save/award', reqBody);
  }
  public getwinnerList(award: string): Observable<AwardWinner> {
    let reqBody = {
      typeOfAward: award,
    };
    return this.http.post<AwardWinner>('contripoint/display/winner', reqBody);
  }

  public extendedMail(): Observable<Mail> {
    return this.http.get<Mail>('contripoint/deadline/extended/email');
  }

  public nominationStartedMail(): Observable<Mail> {
    return this.http.get<Mail>('contripoint/nomination/open/email');
  }

  public reminderMail(): Observable<Mail> {
    return this.http.get<Mail>('contripoint/reminder/nomination/email');
  }

  public checkScheduleNomination(reqBody: any): Observable<any> {
    return this.http.post<any>(
      'contripoint/check/schedule/nomination',
      reqBody
    );
  }

  public scheduleNomination(reqBody: ScheduledNominationData): Observable<any> {
    return this.http.post<ScheduledNominationData>(
      'contripoint/schedule/nomination',
      reqBody
    );
  }

  public getLastScheduledData(): Observable<Array<string>> {
    return this.http.get<Array<string>>('contripoint/last/scheduled/at');
  }

  public startNomination(
    reqBody: boolean
  ): Observable<CheckScheduledNomination> {
    return this.http.post<CheckScheduledNomination>(
      'contripoint/toggle/nomination',
      reqBody
    );
  }

  public getLastData(): Observable<any> {
    return this.http.get<any>('contripoint/timer/nominations');
  }

  public downloadExcel(
    startDate: string,
    endDate: string,
    pageNo: number
  ): Observable<Array<NominationReview>> {
    let requestBody = {
      fromDate: `${startDate} 00:00:00.001`,
      toDate: `${endDate} 23:59:59.999`,
      pageNo: pageNo,
    };
    return this.http.post<Array<NominationReview>>(
      'contripoint/download/excel',
      requestBody
    );
  }
  public nomineeVerification(recommendedToEmail: string): Observable<any> {
    let reqBody = {
      employeeEmail: recommendedToEmail,
    };
    return this.http.post<any>('contripoint/previous/award/verify', reqBody);
  }
  public getAnalyticData(
    verticalValue: Array<string>,
    startDate: string,
    endDate: string,
    shoreValue: string
  ): Observable<Analytics> {
    let requestBody = {
      verticals: verticalValue,
      location: shoreValue,
      fromDate: `${startDate} 00:00:00.001`,
      toDate: `${endDate} 23:59:59.999`,
    };

    return this.http.post<Analytics>('contripoint/analytics/hr', requestBody);
  }

  public reminderScheduler(reqBody: scheduler_data): Observable<Reminder> {
    return this.http.post<Reminder>('contripoint/schedule/reminder', reqBody);
  }

  public reminderStop(date: string): Observable<Reminder> {
    let reqBody = {
      endDate: date,
    };
    return this.http.post<Reminder>('contripoint/stop/reminder', reqBody);
  }

  bannerVisibility(startDate: string, endDate: string): Observable<Reminder> {
    let reqBody = {
      fromDate: `${startDate} 00:00:00.001`,
      toDate: `${endDate} 23:59:59.999`,
    };
    return this.http.post<Reminder>(
      'contripoint/display/winner/list/button',
      reqBody
    );
  }

  winnersAwardList(): Observable<Array<GetAwardListDetails>> {
    return this.http.get<Array<GetAwardListDetails>>(
      'contripoint/get/winners/award'
    );
  }

  getHrAccess(): Observable<boolean> {
    return this.http.post<boolean>('contripoint/access/hr', {});
  }
}
