import { SocketService } from 'src/app/services/socket.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { MyProfileService } from 'src/app/modules/dashboard/dash-info-cards/service/my-profile.service';
import { EmployeeDetails } from 'src/app/shared/models/profile.model';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ViewProfileComponent } from 'src/app/modules/dashboard/view-profile/view-profile.component';
import { NominationService } from 'src/app/modules/hr-recommendation/services/nomination.service';
import * as moment from 'moment';
import { NominationStatus } from 'src/app/shared/models/nomination-award-type.model';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.css'],
})
export class SideNavbarComponent implements OnInit {
  empId: String;
  mobile: boolean;
  empEmail: String;
  isLoggedIn: boolean;
  isAdmin: boolean;
  isAdminManager: boolean;
  isHr: boolean;
  admin: boolean;
  checkManager: boolean;
  userData: EmployeeDetails;
  userImage: string;
  managerFlow: boolean;
  coChairFlow: boolean;
  routeHere: string;
  nominationActive: boolean;
  ecStatus: boolean;
  onHover: string;
  transactionAccess: any;
  constructor(
    private _responsiveService: ResponsiveService,
    private _authService: AuthUserService,
    public dialog: MatDialog,
    private profileService: MyProfileService,
    private nominationService: NominationService,
    public commonService: CommonService
  ) {
    this.empEmail = '';
    this.mobile = false;
    this.userData = {} as EmployeeDetails;
    this.empId = '';
    this.checkManager = false;
    this.isLoggedIn = false;
    this.isAdmin = false;
    this.isAdminManager = false;
    this.admin = false;
    this.userImage = '';
    this.managerFlow = false;
    this.coChairFlow = false;
    this.isHr = false;
    this.routeHere = '';
    this.nominationActive = false;
    this.onHover = '';
    this.ecStatus=false;
  }
  ngOnInit(): void {
    this.intialData();
    this.checkWidth();
    this.onResize();
    this.commonService.setCoChair(this.coChairFlow);
  }

  intialData() {
    this._authService.getActiveCouncil()
      .pipe(
        switchMap((res) => {
          this.ecStatus = res?.[0]?.value || false;
          return this.profileService.getDetails();
        })
      )
      .subscribe(
        (item) => {
          if (item) {
            this.userImage = item?.data?.image
              ? atob(item.data.image)
              : 'assets/images/def.png';
  
            this.checkManager =
              item.isEcManager || item.isDcManager || item.isRm;
            this.isAdmin = item.admin;
            this.isAdminManager = item.isAdminManager;
            this.isLoggedIn = true;
            this.isHr = item.isHR;
            this.transactionAccess = item.getAccessToTransactionAnalytics;
  
            this.coChairFlow =
              (this.ecStatus && item.isEcManager) || item.isDcManager;
  
            this.managerFlow =
              item.isDcManager || item.isRm || item.isAdminManager;
          } else {
            this.isLoggedIn = false;
          }
  
          this.checkScheduledNomination();
        },
        (error) => {
          console.error('Error fetching data', error);
        }
      );
  }
  
  route = (): void => {
    if (this.coChairFlow) {
      this.routeHere = '/award-nomination';
      this.onHover = 'Award Nominations';
    } else {
      this.onHover = 'Employee Recommedation';
      this.routeHere = '/employee-feedback';
    }
  };
  onResize(): void {
    this._responsiveService.checkWidth();
  }
  checkWidth(): void {
    this._responsiveService.getMobileStatus().subscribe((isMobile) => {
      if (isMobile) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '736px',
      maxHeight: '700px',
      panelClass: 'mClass',
    });
  }
  contributionType(type: string) {
    localStorage.setItem('contribution', type);
  }

  checkScheduledNomination() {
    const currentDate = moment(new Date()).format('YYYY/MM/DD');
    const moments = require('moment');
    const currentTime = moments().format('HH:mm');

    let reqBody = {
      startDate: currentDate,
      startTime: currentTime,
      endDate: '',
      endTime: '',
      remainingTime: '',
      portal: '',
      toggle: '',
    };

    this.nominationService
      .checkScheduleNomination(reqBody)
      .subscribe((data) => {
        if (data) {
          this.nominationActive = data?.portal;
          let obj: NominationStatus = {} as NominationStatus;
          obj.chair = this.coChairFlow;
          obj.isNominationStarted = this.nominationActive;
          this.commonService.getNominationStatus.next(obj);
          this.route();
        }
      });
  }
}
