import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MyProfileService } from 'src/app/modules/dashboard/dash-info-cards/service/my-profile.service';
import { AuthUserService } from 'src/app/services/auth-user.service';

@Injectable({
  providedIn: 'root',
})
export class NominationGuard implements CanActivate {
  constructor(
    private authUserService: AuthUserService,
    private router: Router,
    private profileService: MyProfileService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return forkJoin([
      this.authUserService.getActiveCouncil(),
      this.profileService.getDetails(),
    ]).pipe(
      map(([councilData, profileData]) => {
        const ecStatus = councilData?.[0]?.value || false;
        const isAuthorized =
          profileData?.isDcManager || (ecStatus && profileData?.isEcManager);

        return isAuthorized || this.router.createUrlTree(['/main-dashboard']);
      }),
      catchError(() => of(this.router.createUrlTree(['/main-dashboard'])))
    );
  }
}
