import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AwardCount } from 'src/app/shared/models/award.modal';
import { AwardDetails } from 'src/app/shared/models/award.modal';
import { RecommendationStaus } from 'src/app/shared/models/nomineeDetails.model';
import {
  EmployeeDetails,
  NeverRecommendedDetails,
} from 'src/app/shared/models/profile.model';

@Injectable({
  providedIn: 'root',
})
export class AwardServiceService {
  constructor(private http: HttpClient) {}
  updateTab = new BehaviorSubject<any>('');

  /**
   * get the nominated by me data
   */
  getNominateByMe = (empEmail: String, minDate: string, maxDate: string) => {
    let obj = {
      recommendedByEmail: empEmail,
      fromDate: `${minDate} 00:00:00.001`,
      toDate: `${maxDate} 23:59:59.999`,
    };
    return this.http.post<any>('contripoint/nominated/by/me', obj);
  };

  /**
   * get the review recommendation data
   */
  getReviewRecommendation = (
    ecdc: String,
    vertical: String,
    minDate: string,
    maxDate: string
  ) => {
    let obj = {
      vertical: vertical,
      fromDate: `${minDate} 00:00:00.001`,
      toDate: `${maxDate} 23:59:59.999`,
      ecDc: ecdc,
    };
    return this.http.post<any>('contripoint/review/recommendations/ecdc', obj);
  };

  /**
   * get EC vertical
   */
  getEcVertical = () => {
    return this.http.get<any>('contripoint/fetch/dropdown/ec-vertical');
  };
  
  /**
   * get DC Vertical
   */
  getDcVertical = () => {
    return this.http.get<any>('contripoint/fetch/dropdown/dc-vertical');
  };

  /**
   * get the nominee details by it respective ID
   */
  getReviewRecommendationByID = (
    recommendedByEmail: String,
    minDate: string,
    maxDate: string,
    ecdc: string
  ) => {
    let obj = {
      recommendedByEmail: recommendedByEmail,
      fromDate: `${minDate} 00:00:00.001`,
      toDate: `${maxDate} 23:59:59.999`,
      ecdc: ecdc,
    };
    return this.http.post<any>('contripoint/recommendation/by/id', obj);
  };

  /**
   * get the award list of available awards
   */
  getAwardList = (
    nominatedByEmail: string,
    nominatedToEmail: string,
    EcDc: string,
    vertical: string
  ) => {
    let obj = {
      nominatedByEmail: nominatedByEmail,
      nominatedToEmail: nominatedToEmail,
      ecDc: EcDc,
      vertical: vertical,
    };
    return this.http.post<AwardCount[]>('contripoint/check/award/count', obj);
  };

  /**
   * DC vertical for nominations
   */
  getDcNominatedVertical = () => {
    return this.http.get<Array<string>>('contripoint/fetch/nominated/dc-vertical');
  };

  /**
   * EC vertical for nominations
   */
  getEcNominatedVertical = () => {
    return this.http.get<Array<string>>('contripoint/fetch/nominated/ec-vertical');
  };

  /**
   * get the recommendation data
   */
  getRecommendData = (
    recommendedByEmail: String,
    minDate: string,
    maxDate: string,
    ecdc: string
  ) => {
    let obj = {
      recommendedByEmail: recommendedByEmail,
      fromDate: `${minDate} 00:00:00.001`,
      toDate: `${maxDate} 23:59:59.999`,
      ecdc: ecdc,
    };
    return this.http.post<any>('contripoint/get/recommended-by-id', obj);
  };

  /**
   * create nominee
   */
  createNominate = (createEmpRecommendationReqBody: FormData) => {
    return this.http.post<any>(
      'contripoint/nominate',
      createEmpRecommendationReqBody
    );
  };

  /**
   * save as draft
   */
  saveAsDraft = (createEmpRecommendationReqBody: FormData) => {
    return this.http.post<any>(
      'contripoint/nomination/drafts',
      createEmpRecommendationReqBody
    );
  };

  /**
   * load draft data
   */
  draftData = (
    recommendedByEmail: String,
    minDate: string,
    maxDate: string
  ) => {
    let obj = {
      recommendedByEmail: recommendedByEmail,
      fromDate: `${minDate} 00:00:00.001`,
      toDate: `${maxDate} 23:59:59.999`,
    };
    return this.http.post<any>('contripoint/draft/list', obj);
  };

  /**
   * delete nomination with respect to ID
   */
  deleteNominate(id: number) {
    return this.http.delete('contripoint/recommendation/delete/' + id);
  }

  /**
   * revert back nomination data
   */
  revertBackNomination = (createEmpRecommendationReqBody: FormData) => {
    return this.http.post<any>(
      'contripoint/nominate/revert/back',
      createEmpRecommendationReqBody
    );
  };

  /**
   * get the count 0f total recommendation , availabe and nominated
   */
  getCount = (
    ecdc: String,
    vertical: String,
    minDate: string,
    maxDate: string
  ) => {
    let obj = {
      vertical: vertical,
      fromDate: `${minDate} 00:00:00.001`,
      toDate: `${maxDate} 23:59:59.999`,
      ecDc: ecdc,
    };
    return this.http.post<any>('contripoint/get/count/by/id', obj);
  };

  public getAwards(): Observable<Array<AwardDetails>> {
    return this.http.get<Array<AwardDetails>>('contripoint/get/awards');
  }

  getNeverAwarded(data: any): Observable<Array<RecommendationStaus>> {
    let obj = {
      vertical: data.verticalValue,
      fromDate: `${data.minDate} 00:00:00.001`,
      toDate: `${data.maxDate} 23:59:59.999`,
      ecDc: data.ecdc,
    };

    return this.http.post<any>('contripoint/never/awarded', obj);
  }

  getNeverNominatedData(data: any): Observable<Array<RecommendationStaus>> {
    let obj = {
      vertical: data.verticalValue,
      fromDate: `${data.minDate} 00:00:00.001`,
      toDate: `${data.maxDate} 23:59:59.999`,
      ecDc: data.ecdc,
    };

    return this.http.post<any>('contripoint/never/nominated', obj);
  }

  neverRecommendedData(data: any): Observable<Array<NeverRecommendedDetails>> {
    let obj = {
      vertical: data.verticalValue,
      fromDate: `${data.minDate} 00:00:00.001`,
      toDate: `${data.maxDate} 23:59:59.999`,
      ecDc: data.ecdc,
    };

    return this.http.post<Array<NeverRecommendedDetails>>(
      'contripoint/never/recommended',
      obj
    );
  }
}
